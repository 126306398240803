import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Header } from '../components/Acf/Header';
import Leads from '../components/Leads';
// import PipedriveForm from '../components/PipedriveForm';
import ProjectList from '../components/Posts/ProjectList';
import { decodeEntities } from '../utils/helpers';
import Heading from '../components/Heading/Heading';
import './projects.scss';

export default class ProjectIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { allWordpressWpProjects, wordpressPost: page, categoryFilter, wordpressWpSettings, siteSettings } = data;
    const { title: siteTitle, siteUrl } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpProjects;
    const {slug } = data.wordpressPost;
    const { title, yoast, header } = page;
    const { options: {
      projectsGlobalContactFormHeading,
      projectsGlobalContactFormIntro
    } } = siteSettings;
    // const canonicalAttr = `${siteUrl}/${slug}/`;
  const canonicalAttr = yoast.canonical ? yoast.canonical : `${siteUrl}/${slug}/`;
    console.log('project');
    
    return (
      <Layout location={location}>
        <SEO

          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          canonical={canonicalAttr}
          
        />
        <Header {...header} location={location} />
        <ProjectList
          posts={posts}
          title="Latest projects"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          categoryFilterLinks="false"
          siteMetadata={wordpressWpSettings}
          pathPrefix="project-gallery"
        />
        <div className="project-gallery-contact-section">
          {(projectsGlobalContactFormHeading || projectsGlobalContactFormIntro) && (
            <div className="contact-intro">
              {projectsGlobalContactFormHeading && <Heading tag="h2" className="section-title center">{decodeEntities(projectsGlobalContactFormHeading)}</Heading>}
              {projectsGlobalContactFormIntro && (
                <div className="contact-intro-content">
                  <p>{projectsGlobalContactFormIntro}</p>
                </div>
              )}
            </div>
          )}
          {/* <PipedriveForm /> */}
          <Leads id={15} />
        </div>
      </Layout>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProjects: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectIndexQuery($slug: String!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        projectsGlobalContactFormHeading
        projectsGlobalContactFormIntro
        enableProjectsStaticBlock
        projectsGlobalStaticBlockImage {
          source_url
        }
        projectsGlobalStaticBlockTitle
        projectsGlobalStaticBlockContent
        projectsGlobalStaticBlockAction {
          title
          url
          target
        }
      }
    }
    wordpressPost: wordpressPage(slug: { eq: $slug }) {
      title
      slug
      yoast {
        metaTitle: title
        metaDescription: metadesc
        canonical: canonical
      }
      header: acf {
        title
        breadcrumbs
        headerContent
        mediaType
        image {
          source_url
        }
        video
        actions {
          link {
            url
            title
            target
          }
        }
      }
    }
    categoryFilter: allWordpressWpProjects {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
