import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'
import './ProjectList.scss';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    const { pageContext } = props;
    this.state = {
      visibleCount: pageContext.itemsPerPage,
      showingAll: false,
      currentCatSlug: null
    }
  }

  renderMoreProjects = () => {
    const { pageContext, posts } = this.props;
    const { visibleCount } = this.state;
    const newVisibleCount = visibleCount + pageContext.itemsPerPage;
    this.setState({ visibleCount: newVisibleCount < posts.length ? newVisibleCount : posts.length });
  }

  updateActiveCategory = (category) => {
    this.setState({ currentCatSlug: category });
  }

  render() {
    const { posts, categoryFilter, categoryFilterLinks, pageContext, pathPrefix, siteMetadata} = this.props;
    const { visibleCount, currentCatSlug } = this.state;
    const { itemsPerPage } = pageContext;
    /*const filteredPosts = currentCatSlug ? posts.filter(post => post.node.categories.find(cat => cat.slug === currentCatSlug)) : posts.filter(post => post.node.categories.find(cat => cat.slug !== "3d-designs"));*/
    const filteredPosts = posts.filter(post => !currentCatSlug || post.node.categories.some(cat => cat.slug === currentCatSlug));

    return (
      <section className="project-list">
        <PostListNavigation pathPrefix={pathPrefix} updateActiveCategory={this.updateActiveCategory} categoryFilter={categoryFilter} categoryFilterLinks={categoryFilterLinks} postType="project" activeCat={currentCatSlug} />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {filteredPosts.slice(0,visibleCount).map((slide, index) => {
                  return (
                    <PostListItem key={index} data={slide.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix}  />
                  );
                })}
              </div>
              {filteredPosts.length !== filteredPosts.slice(0,visibleCount).length && (
                <OnVisible bounce onChange={() => this.renderMoreProjects()} percent={100}>
                  {/* <span className="spinner" /> */}
                </OnVisible>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    categories {
      id
      name
      slug
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    slug
    featured_image_url {
      source_url
    }
  }
`
